import * as React from "react"
import Layout from "../layout/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import TextLink from "../components/general/text-link";
import {graphql} from "gatsby";
import SEO from "../components/seo";
import OpenConfigurator from "../components/general/open-configurator";

const DescriptionImage = ({image}) => {
  return (
    <div style={{width: "30%", float: "right"}}>
      <GatsbyImage image={image} />
    </div>
  )
}

const IndexPage = ({data}) => {
  return (
    <Layout>
      <SEO />
      <h1>O nas</h1>


      <strong>MARTECH</strong>

      <p>
        <DescriptionImage image={data.liniowa.childImageSharp.gatsbyImageData}/>

        Od 15 lat zajmujemy się produkcją i montażem konstrukcji metalowych. W tym czasie zaufało nam już ponad 1000
        klientów, dla których zrealizowaliśmy ponad 1500 projektów.
        <br/><br/>
        Nasz sklep internetowy ma służyć jako platforma do składania zamówień do produkcji odbojnic i barier
        przemysłowych.
        <br/><br/>

        Zapraszamy do zapoznania się z naszymi realizacjami na naszej stronie internetowej.
        <br/><br/>

        W razie jakichkolwiek pytań lub wątpliwości zapraszamy do kontaktu z nami pod
        adresem email <TextLink href={"kontakt@odbojnice.sklep.pl"}>kontakt@odbojnice.sklep.pl</TextLink> lub
        telefonicznie pod numerem <TextLink href={"tel: +48 600 819 114"}>+48 600 819 114</TextLink>.
        <br/><br/>

        Serdecznie zapraszamy!<br/>
        MARTECH - producent odbojnic przemysłowych i barier technicznych
      </p>

      <OpenConfigurator />
    </Layout>
  )
}

export const query = graphql`
  query {
    liniowa: file(name: {eq: "liniowa_W"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

export default IndexPage
